@import "styles/mixins/helpers";
@import "styles/mixins/typography";

.wrapper {
  @include abs;
  overflow: hidden;

  :global .rv-xy-plot__axis {
    display: none;
  }
}

.visuals {
  @include abs;
  top: auto;
  display: flex;
  align-items: flex-end;
  transform: scale(0.9);
}

.inverse {
  position: absolute;
  top: 0%;
  left: 0;
  transform: scaleY(-1);
}

.cover {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100vh;
}

.bgMask {
  @include abs;
}

.bgImage {
  @include abs;
  background-size: cover;
}

.graphWrapper {
  display: block;
  width: 100%;
  position: relative;
}

.graphMask {
  position: relative;
}

.offsetCover {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 99%;
  height: 100%;
  background-color: #fff;
  z-index: 1000;
}

.productImage {
  position: absolute;
  bottom: -40px;
  left: 0;
  width: 22vw;
  height: 22vw;
  z-index: 100;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }

  // @for $i from 1 to 12 {
  //   &.active-#{$i} {
  //     img:nth-child(#{$i}) {
  //       display: block;
  //     }
  //   }
  // }
}

$sideOffset: 3.33vw;

%period {
  position: absolute;
  bottom: $sideOffset;
  z-index: 100;
  visibility: hidden;

  > span {
    @include fontMonoMedium;
    font-size: 1.875vw;
  }
}

.periodStart {
  @extend %period;
  left: $sideOffset * 2;
}

.periodEnd {
  @extend %period;
  right: $sideOffset * 2;
}

%data {
  position: absolute;
  bottom: $sideOffset *2.5;
  z-index: 100;
  visibility: hidden;

  > span {
    display: block;
    text-align: center;
    
    &:first-child {
      @include fontMainBold;
      font-size: 4.9vw;
      letter-spacing: 0px;
      line-height: 1.042;
    }
    &:last-child {
      @include fontMonoMedium;
      font-size: 1.875vw;
    }
  }
}

.dataStart {
  @extend %data;
  left: $sideOffset * 2;
}

.dataEnd {
  @extend %data;
  right: $sideOffset * 2;
}
