@import "../../styles/mixins/helpers";

.slide {
  @include abs;
  display: none;

  &.isActive {
    display: block;
  }
}
