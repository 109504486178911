@font-face {
  font-family: "IBMPlexMono-Medium";
  src: url("/assets/fonts/IBMPlexMono-Medium.ttf") format("truetype");
}

@font-face {
  font-family: "Trade-Gothic-LT-Bol-Condensed";
  src: url("/assets/fonts/Trade\ Gothic\ LT\ Bold\ Condensed\ No.\ 20.ttf")
    format("truetype");
}
