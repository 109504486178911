.wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 10;

  img {
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }

  video {
    height: 100%;
    width: 100%;
    object-fit: contain;
    background-color: black;
  }
}
