@import "styles/mixins/typography";

.wrapper {
  position: absolute;
  top: 2vw;
  right: 3.5vw;
  visibility: hidden;
}

.tag {
  @include fontMainBold;
  font-size: 3.75vw;
  letter-spacing: -0.025;
  opacity: 0.17;
  filter: saturate(200%);
}
