@import "styles/mixins/helpers";
@import "styles/mixins/typography";

.bgWrapper {
  @include abs;
}

.bgImage {
  @include abs;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.inner {
  @include abs;
  display: flex;
  align-items: flex-end;
  z-index: 10;
}

.contentWrapper {
  position: relative;
  background-color: #fff;
  width: 100%;

  :global .rv-xy-plot__axis {
    display: none;
  }
}

.graphWrapper {
  position: absolute;
  bottom: 100%;
}

.items {
  width: 100%;
  padding: 0 6.7vw 4vw;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
}

.item {
  display: flex;
  visibility: hidden;
  width: 45%;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &:not(:last-child) {
    margin-bottom: 1vw;
  }
}

.label {
  @include labelMono;
  @include fontSize(35);
  text-transform: none;
  text-align: center;
  max-width: 380px;
}

.value {
  @include labelMain;
  @include fontSize(89);
}
