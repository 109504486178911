@mixin fontMainBold {
  font-family: "Trade-Gothic-LT-Bol-Condensed", sans-serif;
  font-weight: bold;
  text-transform: uppercase;
}

@mixin fontMonoMedium {
  font-family: "IBMPlexMono-Medium", sans-serif;
  font-weight: medium;
  text-transform: lowercase;
}

@mixin fontSize($target, $context: 1920) {
  font-size: ($target / $context) * 100 * 1vw;
}

@mixin labelMono {
  @include fontMonoMedium;
  @include fontSize(36);
  line-height: 1.11;
}

@mixin labelMain {
  @include fontMainBold();
  @include fontSize(90);
  line-height: 1.08;
}
