@import "reset-css/sass/reset";
@import "./fonts";
@import "./variables";
@import "./mixins/typography";

@keyframes preloader {
  from {
    opacity: 0;
    transform: translate3d(0, 10vw, 0);
  }
}

* {
  box-sizing: border-box;
}

img {
  max-width: 100%;
}

html {
  overflow: hidden;
}

body {
  color: var(--black);
}

.App {
  height: 100vh;
  width: 100vw;
}

.preloader {
  @include fontMainBold;
  font-size: 5.72vw;
  line-height: 1.045;
  letter-spacing: -0.025em;
  color: var(--color-gray);
  text-indent: -0.2vw;
  display: flex;
  height: 100vh;
  justify-content: center;
  align-items: center;
  animation: preloader 0.2s 0s backwards;
}

.debug {
  position: absolute;
  bottom: 30px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 9999;
}

.arrow {
  background: black;
  cursor: pointer;
  font-size: 22px;
  color: white;
  padding: 15px;
  margin-right: 15px;
}
