@import "styles/mixins/helpers";
@import "styles/mixins/typography";

.wrapper {
  @include abs;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
  background-image: url("http://victorinox-tvscreen-app.elespacio.net/temp_assets/images/social-bg4.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.visuals {
  @include abs;
  // display: flex;
  // align-items: flex-end;
}

.visual {
  display: flex;
  position: absolute;
  height: 100%;
  bottom: 0;
  left: calc(30% - 40%);
  width: 70%;
  align-items: flex-end;

  :global .bg-item {
  }

  :global .fg-item {
    bottom: 5%;
    left: calc(50% - 5%);
    width: 20vw;
  }
}

.description {
  @include fontMonoMedium;
  position: absolute;
  width: 230px;
  bottom: 100px;
  right: 10vw;
  font-size: 1vw;
  line-height: 1.3;
  text-align: center;
}
