@import "styles/mixins/helpers";

.wrapper {
  @include abs;
  overflow: hidden;
}

.visuals {
  @include abs;
  display: flex;
  align-items: flex-end;
}

.inverse {
  position: absolute;
  top: 0%;
  left: 0;
  transform: scaleY(-1);
}

.cover {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100vh;
}

.bgWrapper {
  @include abs;
  visibility: hidden;
}

.bgImage {
  @include abs;
  background-size: cover;
}

.inner {
  @include abs;
  display: flex;
  align-items: flex-end;
  z-index: 100;
  visibility: hidden;
  opacity: 0;
}
