@import "styles/mixins/helpers";
@import "styles/mixins/typography";

.bgWrapper {
  @include abs;
}

.bgImage {
  @include abs;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

.inner {
  @include abs;
  display: flex;
  align-items: flex-end;
  z-index: 10;
}

.contentWrapper {
  position: relative;
  background-color: #fff;
  width: 100%;

  :global .rv-xy-plot__axis {
    display: none;
  }
}

.graphWrapper {
  position: absolute;
  bottom: 100%;
}

.period {
  @include labelMono;
  margin-left: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2vw 0 35%;
  margin-bottom: 1.2vw;

  &Start,
  &End {
    flex: 0 0 33%;
    padding: 0 6%;
    text-align: center;
  }
}

.items {
  width: 100%;
  padding: 0 2vw 4vw 5vw;
}

.item {
  display: flex;
  visibility: hidden;

  &:not(:last-child) {
    margin-bottom: 1vw;
  }
}

.label {
  flex: 0 0 30%;
  padding-right: 7%;
  @include labelMono;
  text-transform: none;
}

.labelInner {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  height: 100%;
}

.values {
  display: flex;
  flex: 0 0 70%;
  position: relative;
  justify-content: space-between;
  align-items: center;
}

%value {
  @include labelMain;
  flex: 0 0 33%;
  display: flex;
  align-items: baseline;
  justify-content: center;
}

.start {
  @extend %value;
}

.end {
  @extend %value;
  margin-left: auto;
}

.difference {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  @include labelMono;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .triangle {
    width: 0;
    height: 0;
    border-left: 2.5vw solid transparent;
    border-right: 2.5vw solid transparent;
  }

  &:not(.decrease) {
    .triangle {
      border-bottom: 1.5vw solid currentColor;
    }
  }
  &.decrease {
    .triangle {
      border-top: 1.5vw solid currentColor;
    }
  }
}

.suffix {
  @include fontSize(60);
  margin-right: -1.56vw;
}
