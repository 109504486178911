@import "styles/mixins/helpers";

.item {
  visibility: hidden;
}

.bg {
}

.fg {
  position: absolute;
  z-index: 100;
  visibility: hidden;
}
