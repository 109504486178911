@import "styles/mixins/typography";

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;
  flex: 0 0 33%;
}

.title,
.measure {
  @include fontMonoMedium;
  font-size: 1.75vw;
}

.title {
  text-transform: capitalize;
  margin-bottom: 0.5vw;
  padding: 0 3vw;
}

.value {
  @include fontMainBold;
  font-size: 4.4vw;
  line-height: 1.042;
  letter-spacing: -0.005;
  margin-bottom: 0.5vw;
}

.measure {
  text-transform: lowercase;
}
