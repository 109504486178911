@import "styles/mixins/helpers";

.wrapper {
  @include abs;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}

.data {
  position: absolute;
  width: 100%;
  z-index: 10;
  display: flex;
  height: 30vw;
  align-items: flex-end;
  justify-content: center;
  padding-bottom: 3vw;
}

.item {
  flex: 0 0 30%;
  text-align: center;
}

.visuals {
  @include abs;
}

.visual {
  display: flex;
  position: absolute;
  height: 100%;
  top: 2%;
  left: calc(50% - 40%);
  width: 70%;

  :global .fg-item {
    bottom: 30%;
    left: calc(50% - 10%);
    width: 40%;
  }
}
