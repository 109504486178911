.info {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: var(--indent);
  z-index: 1000;
}

.visuals {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: calc(100% - var(--indent));
}
