@import "styles/mixins/helpers";

.wrapper {
  @include abs;
  display: flex;
  align-items: flex-end;
  overflow: hidden;
}

.data {
  position: absolute;
  width: 100%;
  z-index: 10;
  display: flex;
  height: 30vw;
  align-items: flex-end;
  justify-content: space-around;
  padding-bottom: 4vw;
}

.item {
  flex: 0 0 33%;
  text-align: center;

  &:nth-child(1) {
    order: -1;
  }

  &:nth-child(3) {
    order: -2;
    margin-left: -2vw;
  }
}

.visuals {
  @include abs;
}

.visual {
  position: absolute;

  &:first-child {
    left: 15%;
    width: 70%;
    top: 6%;

    :global .fg-item {
      bottom: 70%;
      left: calc(50% - 8vw);
      width: 16vw;
    }
  }

  &:nth-child(2) {
    right: -10%;
    width: 50%;
    bottom: 10%;

    :global .fg-item {
      top: -2%;
      left: calc(50% - 7vw);
      width: 14vw;
    }
  }

  &:nth-child(3) {
    left: -10%;
    width: 50%;
    bottom: 23%;

    :global .fg-item {
      bottom: 70%;
      left: calc(50% - 6vw);
      width: 12vw;
    }
  }
}
