@import "styles/mixins/helpers";
@import "styles/mixins/typography";

.wrapper {
  @include abs;
  overflow: hidden;
}

.bgWrapper {
  @include abs;
}

.bgImage {
  @include abs;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
}

// .visuals {
//   @include abs;
// }

.inner {
  @include abs;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  // z-index: 100;
}

.items {
  background: white;
  width: 24vw;
  height: 100vh;
  padding: 120px 40px 40px;
}

.item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &:nth-of-type(1),
  &:nth-of-type(2) {
    flex-direction: column;
    text-align: center;
  }

  &:nth-of-type(3) {
    margin-top: 2vw;
  }

  &:not(:last-child) {
    margin-bottom: 1vw;
  }
}

.label {
  @include labelMono;
  @include fontSize(36);
}

.value {
  @include labelMain;
  @include fontSize(60);
  &.hasEmphasis {
    @include fontSize(92);
  }
}

.rankShape {
  background-size: cover;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 0;
  right: 12%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.rank1 {
  background-image: url("./Rank1.png");
  width: 660px;
  min-height: 488px;
}

.rank2 {
  background-image: url("./Rank2.png");
  width: 568px;
  min-height: 384px;
}

.rank3 {
  background-image: url("./Rank3.png");
  width: 840px;
  min-height: 300px;
  right: 0;
}

.rankTitle {
  @include labelMain;
  @include fontSize(86);
}

.rankSubtitle {
  @include labelMono;
  @include fontSize(36);
}

.rankCaption {
  position: relative;
  bottom: 30px;
}
