@import "styles/mixins/helpers";
@import "styles/mixins/typography";

.wrapper {
  @include abs;
}

.bgImage {
  @include abs;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -1;
}

.inner {
  @include abs;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.items {
  background: white;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  position: relative;
}

.item {
  width: 33%;
  margin-right: 25px;
  &:last-child {
    margin-right: 0;
  }
}

.rank {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
}

.rank1 {
  background-image: url("./Rank1.png");
  width: 660px;
  min-height: 488px;
  left: 50%;
  margin-left: -310px;
  z-index: 30;
}

.rank2 {
  background-image: url("./Rank2.png");
  width: 568px;
  min-height: 384px;
  left: 0;
  margin-left: -25px;
  z-index: 20;
}

.rank3 {
  background-image: url("./Rank3.png");
  width: 840px;
  min-height: 400px;
  left: 50%;
  z-index: 20;
  margin-right: -120px;
}

.rankTitle {
  @include labelMain;
  @include fontSize(86);
}

.rankSubtitle {
  @include labelMono;
  @include fontSize(36);
}

.rankCaption {
  position: relative;
  bottom: 30px;
}
