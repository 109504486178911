@import "../../styles/mixins/typography";

.wrapper {
  position: absolute;
  z-index: 100;
  display: flex;
  flex-direction: column;
  align-items: center;
  visibility: hidden;

  &.normal {
    bottom: 7%;
  }

  &.flipped {
    top: 7%;
  }

  &.start {
    left: 7%;

    .value {
      font-size: 4.895vw;
    }

    .measure {
      margin-bottom: 0.5vw;
      margin-right: 1vw;
    }
  }

  &.end {
    right: 7%;

    .value {
      font-size: 7.3vw;
    }

    .measure {
      margin-bottom: 0.89vw;
      margin-right: 1vw;
    }
  }
}

.top {
  display: flex;
  align-items: flex-end;
}

.measure {
  @include fontMainBold;
  font-size: 18px;
}

.value {
  @include fontMainBold;
  font-size: 4.895vw;
}

.suffix {
  @include fontMainBold;
  font-size: 2.5vw;
  margin-bottom: 0.3vw;
}

.time {
  @include fontMonoMedium;
  font-size: 1.875vw;
  line-height: 1.11;
  letter-spacing: -0.005em;
  max-width: 10vw;
  text-align: center;
}
