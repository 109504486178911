@import "../../styles/mixins/typography";

.wrapper {
  position: absolute;
  z-index: 100;
  color: #fff;
  right: 5%;
  display: flex;
  align-items: flex-end;
  visibility: hidden;

  &.normal {
    top: 10%;
  }

  &.flipped {
    bottom: 10%;
  }
}

.prefix {
  @include fontMainBold;
  font-size: 16.4vw;
}

.value {
  @include fontMainBold;
  font-size: 16.4vw;
}

.suffix {
  @include fontMainBold;
  font-size: 8.3vw;
  margin-bottom: 1.25vw;
}
