@import "../../styles/mixins/typography";

.wrapper {
  padding: 4vw;
  height: 100%;
  box-shadow: 10px 0px 28px 3px rgba(0, 0, 0, 0.28);

  &.whiteText {
    color: #fff;
  }
}

.categoryWrapper {
  margin-bottom: 3.3vw;
}

.category {
  @include fontMonoMedium;
  font-size: 2.6vw;
}

.titleWrapper {
  margin-bottom: 1.5vw;
}

.title {
  @include fontMainBold;
  @include fontSize(170);
  line-height: 0.91;
  letter-spacing: -0.025em;
  text-indent: -0.4vw;
}

.titleSmall {
  @extend .title;
  @include fontSize(130);
}

.subtitle {
  @include fontMainBold;
  @include fontSize(110);
  line-height: 1.045;
  letter-spacing: -0.025em;
  color: var(--color-gray);
  text-indent: -0.2vw;

  .whiteText & {
    color: #fff;
  }
}

.subtitleSmall {
  @extend .subtitle;
  @include fontSize(88);
}

.description {
  margin-top: 2vw;
  @include fontMonoMedium;
  @include fontSize(36);
  text-transform: uppercase;
  // font-size: 3vw;
  line-height: 1.2;
}

.descriptionSmall {
  @extend .description;
  font-size: 2.5vw;
}

.categoryWrapper,
.titleWrapper,
.descriptionWrapper,
.subtitleWrapper {
  visibility: hidden;
  will-change: transform, opacity;
}
